.contentN{
    text-align:center;
    padding: 5rem;
    /* margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem; */
}

.textN{
    margin-top: 3rem;
    margin-left: 8rem;
    margin-right: 8rem;
}

.textN > p > b{
    color: dimgray;
}

.cardN{
    background-color: white;
    height: 125px;
    margin-top: 2rem;
    border-radius: 3rem;
    align-items: center;
    /* display: flex; */
}

.cardL{
    display: flex;
}

.cardR{
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

.cardNT{
    background-color: #096dd9;
    border-radius: 3rem;
    width: 100px;
    height: 100px;
    flex: 30%;
    color: white;
}

.cardNT-L{
    margin-left: 0.8rem;
}

.cardNT-R{
    margin-right: 0.8rem;
    margin-top: 0.8rem;
    float: right;
    width: 28.3%;
}

.cardNT > h2{
    padding-top: 27px;
    color: white;
}

.cardNTDR{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    flex: 70%;
}

.cardNTDL{
    margin-right: 1rem;
    margin-left: 1rem;
    padding-top: 2rem;
    /* flex: 70%; */
    width: 65%;
}


@media screen and (max-width: 1000px){
    .cardN{
        height: auto;
        display: block;
        padding-top: 0.8rem;
    }

    .cardNT{
        width: auto;
        height: auto;
    }

    .cardNT-L{
        margin-right: 0.8rem;
    }

    .cardNT-R {
        margin-left: 0.8rem;
        margin-top: 0;
        float: none;
    }

    .cardNTDR{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
        padding-bottom: 1rem;
    }

    .cardNTDL{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -1.8rem;
        padding-bottom: 1rem;
        width: auto;
    }

    .cardNT > h2{
        padding-top: 0;
    }
  }

@media screen and (max-width: 768px){
    .textN{
        margin-top: 3rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
  }

  @media screen and (max-width: 500px){
    .contentN{
        padding: 2rem;
    }
    .textN{
        margin-left: 0;
        margin-right: 0;
    }
  }