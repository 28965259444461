.contentContact{
    text-align:center;
    height: 500px;
    margin: 5rem;
}

@media screen and (max-width: 500px){
    .contentContact{
        margin: 2rem;
    }
  }