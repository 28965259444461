.contentStyle{
    height: 600px;
    
  }

  .img-Slider{
    width: 100%;
    height: auto;
  }

  .top-left {
    position: absolute;
    top: 4rem;
    left: 16px;
    color: white;
    font-size: x-large;
    z-index: -10;
  }

  @media screen and (max-width: 768px){
    .contentStyle{
      height: auto;
      
    }
    .ant-carousel{
      padding-top: 3rem !important;
      
    }
  }
