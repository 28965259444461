.contentF{
    height: 200px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    background-color: white;
}

.social-icon{
    font-size: 30px;
    color: dimgrey;
    margin: 1rem;
}

.copyr{
    font-size: 14px !important;
}