/* Provide sufficient contrast against white background */
*{
  font-family: -webkit-pictograph;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

html {
  scroll-behavior: smooth;
  background-color: #f3f3f3;
}

body{
  background-color: #f3f3f3;
  font-size: 17px;
}

.title{
  color:#096dd9;
}

/* .bodyContainer{
  margin-top: 7rem;
} */