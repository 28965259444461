.contentC{
    text-align:center;
    background-color: white;
    /* padding-top: 1rem;
    padding-bottom: 2rem; */
    padding: 5rem;
}

.logosC{
    height: 200px;
}

.logosC > img{
    height: 100%;
}

.tp{
    padding-top: 2rem;
}

@media screen and (max-width: 500px){
    .contentC{
        padding: 2rem;
    }
  }